.skills {
	font-family: Arial, sans-serif;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: white;
	margin-bottom: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.skills h2 {
	margin-bottom: 10px;
	font-size: 1.5rem;
}

.skills ul {
	list-style: none;
	padding: 0;
}

.skills li {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
}

/* Add more styling as needed */
